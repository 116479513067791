export const TABLE_RESERVATIONS_PAGE_ID = 'Reservations';
export const TABLE_RESERVATIONS_DETAILS_PAGE_ID = 'Reservation Details';
export const TABLE_RESERVATIONS_CONFIRMATIONS_PAGE_ID =
  'Reservation Confirmation';

export const MENUS_PAGE_ID = 'menus-page';

export const ORDERS_PAGE_ID = 'menu-orders-page';
export const DISPATCH_MODAL_PAGE_ID = 'dispatchModal';
export const ITEM_MODAL_PAGE_ID = 'itemModal';
export const ERROR_MODAL_PAGE_ID = 'errorModal';
export const TRACKER_PAGE_ID = 'tracker';
export const CART_PAGE_ID = 'shopping_cart';
export const THANK_YOU_PAGE_ID = 'thank_you_page';

export const MENUS_PAGES_GROUP = [MENUS_PAGE_ID];
export const TABLE_RESERVATIONS_GROUP = [
  TABLE_RESERVATIONS_PAGE_ID,
  TABLE_RESERVATIONS_DETAILS_PAGE_ID,
  TABLE_RESERVATIONS_CONFIRMATIONS_PAGE_ID,
];
export const ORDERS_PAGES_GROUP = [
  ORDERS_PAGE_ID,
  DISPATCH_MODAL_PAGE_ID,
  ITEM_MODAL_PAGE_ID,
  ERROR_MODAL_PAGE_ID,
  TRACKER_PAGE_ID,
  CART_PAGE_ID,
  THANK_YOU_PAGE_ID,
];

export const DASHBOARD_URLS = {
  menusUrl: '/restaurants/menus-management/',
  tableReservationsUrl: '/restaurants/table-reservations/',
  tableReservationsSettingsUrl: '/restaurants/table-reservations/settings/',
};

export const OLO_APP_DEF_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60';
export const MENUS_APP_DEF_ID = 'b278a256-2757-4f19-9313-c05c783bec92';
export const TABLE_RESERVATIONS_APP_DEF_ID =
  'f9c07de2-5341-40c6-b096-8eb39de391fb';

export const MENUS_APP_IMAGE_GALLERY_LIGHTBOX = 'menusImageGallery';
