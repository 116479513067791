import type {
  AppExposedApis,
  EditorReadyFn,
  EditorSDK,
  PopupData,
} from '@wix/platform-editor-sdk';
import type { GetAppManifestFn, TFunction } from '@wix/yoshi-flow-editor';
import {
  ORDERS_PAGES_GROUP,
  MENUS_PAGES_GROUP,
  TABLE_RESERVATIONS_GROUP,
  TABLE_RESERVATIONS_PAGE_ID,
  DASHBOARD_URLS,
  ORDERS_PAGE_ID,
  OLO_APP_DEF_ID,
  MENUS_APP_DEF_ID,
  TABLE_RESERVATIONS_APP_DEF_ID,
  MENUS_PAGE_ID,
  TABLE_RESERVATIONS_CONFIRMATIONS_PAGE_ID,
  TABLE_RESERVATIONS_DETAILS_PAGE_ID,
  MENUS_APP_IMAGE_GALLERY_LIGHTBOX,
} from './utils/consts';
import {
  getDeleteAction,
  getDuplicateAction,
  getRenameAction,
  getSetAsHomePageAction,
} from './utils/pageActions';

const TOKEN = '';

export const editorReady: EditorReadyFn = async () => {};

const getSettingsTabs = (t: TFunction) => {
  const pageInfo = {
    title: t('editor-script.actions.page-info'),
    type: 'page_info' as const,
  };

  const layout = {
    title: t('editor-script.actions.layout'),
    type: 'layout' as const,
  };

  const seo = {
    title: t('editor-script.actions.seo'),
    type: 'seo' as const,
  };

  const permissions = {
    title: t('editor-script.actions.permissions'),
    type: 'permissions' as const,
  };

  return {
    pageInfo,
    layout,
    seo,
    permissions,
  };
};

export const getAppManifest: GetAppManifestFn = (
  { appManifestBuilder },
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const t = flowAPI.translations.t as TFunction;
  const settingsTabs = getSettingsTabs(t);
  const isEditorX = flowAPI.environment.isEditorX;
  const experiments = flowAPI.experiments;
  const isMenusPageActionsExperimentEnabled = experiments?.enabled(
    'specs.restaurants.menus-page-actions',
  );
  const isDeleteMenusPageActionEnabled = experiments?.enabled(
    'specs.restaurants.deleteMenusAppPageAction',
  );
  const deleteOrdersAppActionEnabled = experiments?.enabled(
    'specs.restaurants.deleteOrdersAppPageAction',
  );
  const deleteReservationsAppActionEnabled = experiments?.enabled(
    'specs.restaurants.deleteReservationsNewAppPageAction',
  );

  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      pagesBuilder.set({ icon: 'restaurantsPageType' });
      pagesBuilder.addSettingsTab(settingsTabs.permissions);
      pagesBuilder.addSettingsTab(settingsTabs.layout);
      pagesBuilder.configureState(
        TABLE_RESERVATIONS_PAGE_ID,
        (stateBuilder) => {
          stateBuilder
            .addAction(getRenameAction(t, isEditorX))
            .addAction(getSetAsHomePageAction(t, isEditorX))
            .addSettingsTab(settingsTabs.pageInfo)
            .addSettingsTab(settingsTabs.permissions)
            .addSettingsTab(settingsTabs.layout)
            .addSettingsTab(settingsTabs.seo);
          deleteReservationsAppActionEnabled &&
            stateBuilder.addAction(getDeleteAction(t, isEditorX));
        },
      );
      pagesBuilder.configureState(
        TABLE_RESERVATIONS_DETAILS_PAGE_ID,
        (stateBuilder) => {
          stateBuilder
            .addSettingsTab(settingsTabs.pageInfo)
            .addSettingsTab(settingsTabs.permissions)
            .addSettingsTab(settingsTabs.layout);
        },
      );
      pagesBuilder.configureState(
        TABLE_RESERVATIONS_CONFIRMATIONS_PAGE_ID,
        (stateBuilder) => {
          stateBuilder
            .addSettingsTab(settingsTabs.pageInfo)
            .addSettingsTab(settingsTabs.permissions)
            .addSettingsTab(settingsTabs.layout);
        },
      );
      pagesBuilder.configureState(MENUS_PAGE_ID, (stateBuilder) => {
        stateBuilder
          .addAction(getRenameAction(t, isEditorX))
          .addAction(getSetAsHomePageAction(t, isEditorX))
          .addSettingsTab(settingsTabs.pageInfo)
          .addSettingsTab(settingsTabs.permissions)
          .addSettingsTab(settingsTabs.layout);
        isMenusPageActionsExperimentEnabled &&
          stateBuilder.addAction(getDuplicateAction(t, isEditorX));
        isDeleteMenusPageActionEnabled &&
          stateBuilder.addAction(getDeleteAction(t, isEditorX));
      });
      pagesBuilder.configureState(ORDERS_PAGE_ID, (stateBuilder) => {
        stateBuilder
          .addAction(getRenameAction(t, isEditorX))
          .addAction(getSetAsHomePageAction(t, isEditorX))
          .addSettingsTab(settingsTabs.pageInfo)
          .addSettingsTab(settingsTabs.permissions)
          .addSettingsTab(settingsTabs.layout)
          .addSettingsTab(settingsTabs.seo);
        deleteOrdersAppActionEnabled &&
          stateBuilder.addAction(getDeleteAction(t, isEditorX));
      });
    })
    .configurePagesTab((pagesTabBuilder) => {
      // application settings
      pagesTabBuilder
        .set({
          displayName: t('editor-script.panel.section-name'),
        })
        .setGrouping([
          {
            id: 'wix-menus',
            title: t('editor-script.groups.menus'),
            pages: MENUS_PAGES_GROUP,
          },
          {
            id: 'wix-online-orders',
            title: t('editor-script.groups.orders'),
            pages: ORDERS_PAGES_GROUP,
          },
          {
            id: 'wix-table-reservations',
            title: t('editor-script.groups.reservations'),
            pages: TABLE_RESERVATIONS_GROUP,
          },
        ]);
    })
    .configureManagementActions((managementActionsBuilder) => {
      managementActionsBuilder.customActions().addAction(
        {
          title: 'Manage Menus',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
          onClick: (event) => {
            editorSDK.editor.openDashboardPanel(TOKEN, {
              url: DASHBOARD_URLS.menusUrl,
              closeOtherPanels: false,
            });
          },
        },
        {
          title: 'Manage Table Reservations',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
          onClick: (event) => {
            editorSDK.editor.openDashboardPanel(TOKEN, {
              url: DASHBOARD_URLS.tableReservationsUrl,
              closeOtherPanels: false,
            });
          },
        },
        {
          title: 'Manage Table Reservations Settings',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
          onClick: (event) => {
            editorSDK.editor.openDashboardPanel(TOKEN, {
              url: DASHBOARD_URLS.tableReservationsSettingsUrl,
              closeOtherPanels: false,
            });
          },
        },
      );
    })
    .build();
};

export const exports = (editorSDK: EditorSDK): AppExposedApis => ({
  editor: {
    removeAppCompleted: async ({ appDefinitionId }) => {
      const isMenusAppInstalled =
        await editorSDK.application.isApplicationInstalled('', {
          appDefinitionId: MENUS_APP_DEF_ID,
        });
      const isTableReservationsAppInstalled =
        await editorSDK.application.isApplicationInstalled('', {
          appDefinitionId: TABLE_RESERVATIONS_APP_DEF_ID,
        });
      const isOLOAppInstalled =
        await editorSDK.application.isApplicationInstalled('', {
          appDefinitionId: OLO_APP_DEF_ID,
        });
      const isOnlyOLOLeft =
        appDefinitionId === OLO_APP_DEF_ID &&
        !isMenusAppInstalled &&
        !isTableReservationsAppInstalled;

      const isOnlyMenusLeft =
        appDefinitionId === MENUS_APP_DEF_ID &&
        !isOLOAppInstalled &&
        !isTableReservationsAppInstalled;

      const isOnlyTableReservationsLeft =
        appDefinitionId === TABLE_RESERVATIONS_APP_DEF_ID &&
        !isOLOAppInstalled &&
        !isMenusAppInstalled;

      if (isOnlyOLOLeft) {
        editorSDK.application.uninstall('', { openConfirmation: false });
      } else if (isOnlyMenusLeft) {
        editorSDK.application.uninstall('', { openConfirmation: false });
      } else if (isOnlyTableReservationsLeft) {
        editorSDK.application.uninstall('', { openConfirmation: false });
      }
    },
  },
  public: {
    isImageLightboxInstalled: async () => {
      const installedLightboxes =
        await editorSDK.pages.popupPages.getApplicationPopups(TOKEN);
      return installedLightboxes.find(
        (lightbox: PopupData) =>
          lightbox.title === MENUS_APP_IMAGE_GALLERY_LIGHTBOX,
      );
    },
  },
});
