
  import * as userEditorScriptEntry from '/home/builduser/work/5b05511193dd3b5e/packages/restaurants-editor-script/src/editor.app.ts';
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/5b05511193dd3b5e/packages/restaurants-editor-script/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = null;
  const experimentsConfig = {"centralized":true,"scopes":["restaurants"]};
  const translationsConfig = {"enabled":true,"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/5b05511193dd3b5e/packages/restaurants-editor-script/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"editor-script.panel.section-name":"Restaurant Pages","editor-script.groups.menus":"Menus (New)","editor-script.groups.reservations":"Wix Table Reservations","editor-script.groups.orders":"Online Orders (New)","editor-script.actions.seo":"SEO Basics","editor-script.actions.permissions":"Permissions","editor-script.actions.page-info":"Page info","editor-script.actions.layout":"Layout","editor-script.actions.rename":"Rename","editor-script.actions.set-as-homepage":"Set as Homepage","editor-script.actions.duplicate":"Duplicate","editor-script.actions.delete":"Delete"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'restaurants-editor-script',
    appDefId: '9142e27e-2e30-4948-ad8f-7e128939efc4',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
